import {inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PageBreadcrumb} from '../interfaces/generated/graphql';
import {PageService} from './page.service';

export type BreadcrumbStyle = string;

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService implements OnDestroy {
  private pageService = inject(PageService);


  protected onStyleChangeBehavior: BehaviorSubject<BreadcrumbStyle> = new BehaviorSubject<BreadcrumbStyle>(this.getDefaultStyle());
  protected onBreadcrumbChangeBehavior: BehaviorSubject<PageBreadcrumb[]> = new BehaviorSubject<PageBreadcrumb[]>([]);

  private pageSub: Subscription;
  isAutoChangeEnabled = true;

  constructor() {
    this.pageSub = this.pageService.getPageAsync().subscribe(page => {
      this.setBreadcrumbs(page?.page.breadcrumbs || []);
      this.updateStyle(page?.page.breadcrumbStyle || this.getDefaultStyle());
    })
  }

  ngOnDestroy() {
    this.pageSub.unsubscribe();
  }

  setBreadcrumbs(breadcrumbs: PageBreadcrumb[]) {
    this.onBreadcrumbChangeBehavior.next(breadcrumbs);
  }

  public updateStyle(style: BreadcrumbStyle) {
    if (this.getCurrentStyle() !== style) {
      this.onStyleChangeBehavior.next(style);
    }
  }

  public getCurrentStyle(): BreadcrumbStyle {
    return this.onStyleChangeBehavior.getValue();
  }

  public getDefaultStyle(): BreadcrumbStyle {
    return 'dark';
  }

  getCurrentBreadcrumbsAsync(): Observable<PageBreadcrumb[]> {
    return this.onBreadcrumbChangeBehavior.asObservable();
  }

  getCurrentStyleAsync(): Observable<BreadcrumbStyle> {
    return this.onStyleChangeBehavior.asObservable();
  }
}
